import React, { createContext, ReactNode, useContext, useState } from "react"

interface LanguageContextProps {
    language: string
    setLanguage: (language: string) => void
}

export const LanguageContext = createContext<LanguageContextProps | undefined>(undefined)

export const LanguageProvider = ({ children }: { children: ReactNode }): React.JSX.Element => {
    const [language, setLanguage] = useState("en")

    return (
        <LanguageContext.Provider value={{ language, setLanguage }}>
            {children}
        </LanguageContext.Provider>
    )
}

export const useLanguage = (): LanguageContextProps => {
    const context = useContext(LanguageContext)
    if (!context) {
        throw new Error("useLanguage must be used within a LanguageProvider")
    }
    return context
}
