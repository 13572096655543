exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-all-services-tsx": () => import("./../../../src/templates/allServices.tsx" /* webpackChunkName: "component---src-templates-all-services-tsx" */),
  "component---src-templates-calibration-tsx": () => import("./../../../src/templates/calibration.tsx" /* webpackChunkName: "component---src-templates-calibration-tsx" */),
  "component---src-templates-heathamster-tsx": () => import("./../../../src/templates/heathamster.tsx" /* webpackChunkName: "component---src-templates-heathamster-tsx" */),
  "component---src-templates-heathummingbird-tsx": () => import("./../../../src/templates/heathummingbird.tsx" /* webpackChunkName: "component---src-templates-heathummingbird-tsx" */),
  "component---src-templates-online-monitoring-tsx": () => import("./../../../src/templates/onlineMonitoring.tsx" /* webpackChunkName: "component---src-templates-online-monitoring-tsx" */)
}

