import React from "react"
import { ReactNode } from "react"
import ReactDOM from "react-dom/client"

import { LanguageProvider } from "./src/context/LanguageContext"

import "./src/styles/global.css"

export const wrapRootElement: React.FC<{ element: React.ReactNode }> = ({ element }) => (
    <LanguageProvider>{element}</LanguageProvider>
)

export const replaceHydrateFunction = () => {
    return (element: ReactNode, container: Element | DocumentFragment): void => {
        const root = ReactDOM.createRoot(container)
        root.render(element)
    }
}
